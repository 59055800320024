import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "../Components/Nav";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import html2canvas from "html2canvas";

const ViewP = ({ content, setLang, lang }) => {
  function removeImg() {
    localStorage.removeItem("divImage");
    localStorage.removeItem("divImagee2");
    localStorage.removeItem("srcV1");
    localStorage.removeItem("srcV2");
    localStorage.removeItem("back_text");
    localStorage.removeItem("front_text1");
    localStorage.removeItem("front_text2");
    localStorage.removeItem("front_text3");
  }

  const handleClick = () => {
    const imageElement = document.getElementById("myDiv");
    const imageElement2 = document.getElementById("myDiv2");

    html2canvas(imageElement).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");
      localStorage.setItem("srcV1", imageData);
    });

    html2canvas(imageElement2).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");
      localStorage.setItem("srcV2", imageData);
    });
  };

  return (
    <div>
      <Nav content={content} setLang={setLang} lang={lang} />
      <div className="img_cost view-p">
        <div className="Image">
          <div className="backImg">
            <div className="card2">
              <img
                className="ustki_rasm"
                src={`${localStorage.getItem("div1Image")}`}
                id="myDiv"
                style={
                  localStorage.getItem("side") === "back"
                    ? { transform: "rotateY(180deg)" }
                    : {}
                }
                alt=""
              />
              <img
                className="ustki_rasm"
                src={`${localStorage.getItem("div2Image")}`}
                style={
                  localStorage.getItem("side") === "front"
                    ? { transform: "rotateY(180deg)" }
                    : {}
                }
                id="myDiv2"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="cost">
          <p>{content.all_cost}</p>
          <p>
            {localStorage.getItem("cost")}
            {content.sum}
          </p>
        </div>
      </div>

      <div className="text">
        <p>{content.view_text}</p>
      </div>
      <div className="dalee2">
        <NavLink onClick={() => removeImg()} to="/next-p">
          <button>
            <KeyboardBackspaceIcon />
          </button>
        </NavLink>
        <NavLink to={"/finish"}>
          <button onClick={handleClick}>{content.verily}</button>
        </NavLink>
      </div>
    </div>
  );
};

export default ViewP;
