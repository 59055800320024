import React, { useState } from "react";
import Nav from "../Components/Nav";
import Img from "../Images/Mockup копия12251255 1.svg";
import { NavLink } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfoIcon from "@mui/icons-material/Info";
// import { Button } from "@mui/material";

const Main = ({ setLang, content, lang }) => {
  const [modal, setModal] = useState(false);

  return (
    <div className="main">
      <div
        className={modal ? "info active" : "info"}
        onClick={() => setModal(false)}
      >
        <div className="modal_card">
          <p>
            {content.infoo}
            <br />
            <br />
            {content.infoo1}
            <br />
            <br />
            {content.infoo2}
            <br />
            <br />
            {content.infoo3}
            <br />
            <br />
            {content.infoo4}
            <br />
            <br />
            {content.infoo5}
            <br />
            <br />
            {content.infoo6}
          </p>
        </div>
      </div>
      <div>
        <Nav
          setLang={setLang}
          setModal={setModal}
          content={content}
          lang={lang}
        />
        <div className="Image1">
          <img src={Img} alt="" />
        </div>
      </div>
      <div className="M_text">
        <div className="Main_text">
          <p>{content.title}</p>
          <p>{content.subtitle}</p>
          <p>{content.subtitle2}</p>
        </div>
        <NavLink to="/select-d">
          <button>{content.but1}</button>
        </NavLink>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            fontSize: "12px",
          }}
          onClick={() => setModal(true)}
        >
          <InfoIcon />
          {content.infor1}
        </p>
      </div>
    </div>
  );
};

export default Main;
